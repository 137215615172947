html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.page-container {
  padding: 3em 8vw 3em 8vw;
  max-width: 100%;
  overflow-x: hidden;
}

.row-center{
  @extend .row;
  @extend .align-items-start;
}

.nowrap{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) { 
  .sm-hidden-md-flex { 
    display: none !important; 
  } 
}

@media only screen and (min-width: 600px) { 
  .sm-hidden-md-flex { 
    display: flex !important; 
  } 
}
@media only screen and (max-width: 600px) { 
  .sm-initial-md-hidden { 
    display: initial !important; 
  } 
}

@media only screen and (min-width: 600px) { 
  .sm-initial-md-hidden { 
    display: none !important; 
  } 
}