@import "app";

body {
  margin: 0;
  background-color: #f5f8fa !important;
  font-family: $default-font-family, sans-serif !important;
}

.fullscreen {
  height: 100vh;
  width: 100vw;
}

.space-children-vertical > * {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.space-children-vertical > *:first-child {
  margin-top: 0 !important;
}
.space-children-vertical > *:last-child {
  margin-bottom: 0 !important;
}

.space-children-horizontal > * {
  margin-left: 1em !important;
  margin-right: 1em !important;
}
.space-children-horizontal > *:first-child {
  margin-left: 0 !important;
}
.space-children-horizontal > *:last-child {
  margin-right: 0 !important;
}

.top-most {
  z-index: 99999 !important;
}

.image-contain {
  object-fit: contain;
}

.cursor-pointer {
  cursor: pointer;
}

.animate {
  transition: all 0.3s ease-in-out;
}

.wrap-word {
  word-wrap: break-word;
}

.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}

.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-control-attribution {
  display: none;
}

// TODO: fix autofill
// not working yet
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
.MuiFilledInput-input {
  background-color: transparent !important;
}
// not working yet

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-shadow {
  text-shadow: 0 0 3px black;
}

button:focus,
div:focus {
  outline: none !important;
}

@font-face {
  font-family: "plateia";
  src: local("plateia"), url("../fonts/plateia-bold.ttf") format("truetype");
}

.hide-scrollbar::-webkit-scrollbar{
  display: none !important;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 6px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none;
}