.background-primary {
  background-color: $primary-color !important;
  color: white !important;
}

.background-secondary {
  background-color: $secondary-color !important;
  color: white !important;
}

.color-primary {
  color: $primary-color !important;
}
.color-secondary {
  color: $secondary-color !important;
}
