.dark-on-hover {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    // can simply use filter but does not work on edge and internet explorer
    // filter:brightness(40%)
    &::after {
      content:'';
      background-color: #00000088;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
    }
  }
}