/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #47a7f511 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.card-ribbon{
  width: 150%;
  top: 10%;
  right: -60%;
  transform: rotate(45deg);
  background-color: #E96559;
}