.pill{
  border-radius: 50em !important;
}

.dropzone-input {
  text-align: center;
  border: 1px dotted grey;
  border-radius: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  cursor: pointer;
}
.dropzone-input:hover {
  background-color: #00000011;
}