.general-table {
  border-spacing: 0;

  & > tr > th {
    background-color: #F1F3F9;
  }

  & > tr > th,
  & > tr > td
  {
    text-align: center;
    padding: 1em;
  }
  & > tr:hover
  {
    background-color: #00000008;
  }
}